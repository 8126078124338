import { t } from 'app/i18n'
export default [
  {
    name: t('price.day_month'),
    free: t('price.free'),
    professional: t('price.speciality'),
    enterprise: t('price.professional'),
    enterprisepro: 'qihoo',
    html: true
  },
  {
    name: t('price.basic_detection'),
    free: '',
    professional: '',
    enterprise: '',
    enterprisepro: '',
    title: true
  },
  {
    name: t('price.windows32'),
    free: true,
    professional: true,
    enterprise: true,
    enterprisepro: true,
    desc: t('price.windows32des')
  },
  {
    name: t('price.basic_software'),
    free: true,
    professional: true,
    enterprise: true,
    enterprisepro: true,
    desc: t('price.basic_software_des')
  },
  {
    name: t('price.file_size'),
    free: '2MB',
    professional: '4MB',
    enterprise: '10MB',
    enterprisepro: '10MB',
    desc: t('price.file_size_des')
  },
  {
    name: t('price.detection_time'),
    free: t('price.seconds', { num: 120 }),
    professional: t('price.seconds', { num: 360 }),
    enterprise: t('price.seconds', { num: 660 }),
    enterprisepro: t('price.seconds', { num: 660 }),
    desc: t('price.detection_time_des')
  },
  // {
  //   name: '公开任务展示时间范围',
  //   free: '近3个月',
  //   professional: '近6个月',
  //   enterprise: '近12个月',
  //   enterprisepro: '近12个月',
  //   desc:
  //     '允许展示的公开任务时间范围。'
  // },
  {
    name: t('price.detection_file'),
    free: true,
    professional: true,
    enterprise: true,
    enterprisepro: true,
    desc: t('price.detection_file_des')
  },
  {
    name: t('price.file_way'),
    free: true,
    professional: true,
    enterprise: true,
    enterprisepro: true,
    desc: t('price.file_way_des')
  },
  {
    name: t('price.operator_imitate'),
    free: true,
    professional: true,
    enterprise: true,
    enterprisepro: true,
    desc: t('price.operator_imitate_des')
  },
  {
    name: t('price.fast_detection'),
    free: true,
    professional: true,
    enterprise: true,
    enterprisepro: true,
    desc: t('price.fast_detection_des')
  },
  {
    name: t('price.runtime_restart'),
    free: true,
    professional: true,
    enterprise: true,
    enterprisepro: true,
    desc: t('price.runtime_restart_des')
  },
  {
    name: t('price.violent_password'),
    free: true,
    professional: true,
    enterprise: true,
    enterprisepro: true,
    desc: t('price.violent_password_des')
  },
  {
    name: t('price.hook_sleep'),
    free: true,
    professional: true,
    enterprise: true,
    enterprisepro: true,
    desc: t('price.hook_sleep_des')
  },
  {
    name: t('price.analysis_priority'),
    free: t('price.low'),
    professional: t('price.middle'),
    enterprise: t('price.high'),
    enterprisepro: t('price.high'),
    desc: t('price.analysis_priority_des')
  },
  {
    name: t('price.att_ck'),
    free: true,
    professional: true,
    enterprise: true,
    enterprisepro: true,
    desc: t('price.att_ck_des')
  },
  {
    name: t('price.relation_tree'),
    free: true,
    professional: true,
    enterprise: true,
    enterprisepro: true,
    desc: t('price.relation_tree_des')
  },
  {
    name: t('price.common_tag'),
    free: true,
    professional: true,
    enterprise: true,
    enterprisepro: true,
    desc: t('price.common_tag_des')
  },
  {
    name: t('price.family_tag'),
    free: true,
    professional: true,
    enterprise: true,
    enterprisepro: true,
    desc: t('price.family_tag_des')
  },
  {
    name: t('price.api_call'),
    free: true,
    professional: true,
    enterprise: true,
    enterprisepro: true,
    desc: t('price.api_call_des')
  },
  {
    name: t('price.linux_detection'),
    free: true,
    professional: true,
    enterprise: true,
    enterprisepro: true,
    desc: t('price.linux_detection_des')
  },
  {
    name: t('price.ubuntu64'),
    free: true,
    professional: true,
    enterprise: true,
    enterprisepro: true,
    desc: t('price.ubuntu64_des')
  },
  {
    name: t('price.advance_detection'),
    free: '',
    professional: '',
    enterprise: '',
    enterprisepro: '',
    title: true
  },
  {
    name: t('price.window64'),
    free: false,
    professional: true,
    enterprise: true,
    enterprisepro: true,
    desc: t('price.window64_des')
  },
  {
    name: t('price.andorid_detection'),
    free: false,
    professional: true,
    enterprise: true,
    enterprisepro: true,
    desc: t('price.andorid_detection_des')
  },
  {
    name: t('price.task_setting'),
    free: false,
    professional: true,
    enterprise: true,
    enterprisepro: true,
    desc: t('price.task_setting_des')
  },
  {
    name: t('price.delete_report'),
    free: false,
    professional: true,
    enterprise: true,
    enterprisepro: true,
    desc: t('price.delete_report_des')
  },
  {
    name: t('price.advance_set'),
    free: false,
    professional: true,
    enterprise: true,
    enterprisepro: true,
    desc: t('price.advance_set_des')
  },
  {
    name: t('price.url_browser'),
    free: false,
    professional: true,
    enterprise: true,
    enterprisepro: true,
    desc: t('price.url_browser_des')
  },
  {
    name: t('price.file_open'),
    free: false,
    professional: true,
    enterprise: true,
    enterprisepro: true,
    desc: t('price.file_open_des')
  },
  {
    name: t('price.compressed_file'),
    free: false,
    professional: true,
    enterprise: true,
    enterprisepro: true,
    desc: t('price.compressed_file_des')
  },
  {
    name: t('price.compressed_subfile'),
    free: false,
    professional: true,
    enterprise: true,
    enterprisepro: true,
    desc: t('price.compressed_subfile_des')
  },
  {
    name: t('price.file_password'),
    free: false,
    professional: true,
    enterprise: true,
    enterprisepro: true,
    desc: t('price.file_password_des')
  },
  {
    name: t('price.opetation_install'),
    free: false,
    professional: true,
    enterprise: true,
    enterprisepro: true,
    desc: t('price.opetation_install_des')
  },
  {
    name: t('price.https_decrype'),
    free: false,
    professional: true,
    enterprise: true,
    enterprisepro: true,
    desc: t('price.https_decrype_des')
  },
  {
    name: t('price.file_information'),
    free: false,
    professional: true,
    enterprise: true,
    enterprisepro: true,
    desc: t('price.file_information_des')
  },
  {
    name: t('price.vul_tag'),
    free: false,
    professional: true,
    enterprise: true,
    enterprisepro: true,
    desc: t('price.vul_tag_des')
  },
  {
    name: t('price.ransomware_tag'),
    free: false,
    professional: true,
    enterprise: true,
    enterprisepro: true,
    desc: t('price.ransomware_tag_des')
  },
  {
    name: t('price.evasion_tag'),
    free: false,
    professional: true,
    enterprise: true,
    enterprisepro: true,
    desc: t('price.evasion_tag_des')
  },
  {
    name: t('price.sample_pcap'),
    free: false,
    professional: true,
    enterprise: true,
    enterprisepro: true,
    desc: t('price.sample_pcap_des')
  },
  {
    name: t('price.download_pdf'),
    free: false,
    professional: true,
    enterprise: true,
    enterprisepro: true,
    desc: t('price.download_pdf_des')
  },
  {
    name: t('price.release_file'),
    free: false,
    professional: true,
    enterprise: true,
    enterprisepro: true,
    desc: t('price.release_file_des')
  },
  {
    name: t('price.release_memdump'),
    free: false,
    professional: true,
    enterprise: true,
    enterprisepro: true,
    desc: t('price.release_memdump_des')
  },
  {
    name: t('price.view_release'),
    free: false,
    professional: true,
    enterprise: true,
    enterprisepro: true,
    desc: t('price.view_release_des')
  },
  {
    name: t('price.view_threat'),
    free: false,
    professional: true,
    enterprise: true,
    enterprisepro: true,
    desc: t('price.view_threat_des')
  },
  {
    name: t('price.deep_detection'),
    free: '',
    professional: '',
    enterprise: '',
    enterprisepro: '',
    title: true
  },
  {
    name: t('price.windows8'),
    free: false,
    professional: false,
    enterprise: true,
    enterprisepro: true,
    desc: t('price.windows8_des')
  },
  {
    name: t('price.windows10'),
    free: false,
    professional: false,
    enterprise: true,
    enterprisepro: true,
    desc: t('price.windows10_des')
  },
  {
    name: t('price.deep_sofrware'),
    free: false,
    professional: false,
    enterprise: true,
    enterprisepro: true,
    desc: t('price.deep_sofrware_des')
  },
  {
    name: t('price.date_time_set'),
    free: false,
    professional: false,
    enterprise: true,
    enterprisepro: true,
    desc: t('price.date_time_set_des')
  },
  {
    name: t('price.zone_set'),
    free: false,
    professional: false,
    enterprise: true,
    enterprisepro: true,
    desc: t('price.zone_set_des')
  },
  {
    name: t('price.regional_set'),
    free: false,
    professional: false,
    enterprise: true,
    enterprisepro: true,
    desc: t('price.regional_set_des')
  },
  {
    name: t('price.environment_set'),
    free: false,
    professional: false,
    enterprise: true,
    enterprisepro: true,
    desc: t('price.environment_set_des')
  },
  {
    name: t('price.library_set'),
    free: false,
    professional: false,
    enterprise: true,
    enterprisepro: true,
    desc: t('price.library_set_des')
  },
  {
    name: t('price.active_restart'),
    free: false,
    professional: false,
    enterprise: true,
    enterprisepro: true,
    desc: t('price.active_restart_des')
  },
  {
    name: t('price.fake_av'),
    free: false,
    professional: false,
    enterprise: true,
    enterprisepro: true,
    desc: t('price.fake_av_des')
  },
  {
    name: t('price.known_vul_tag'),
    free: false,
    professional: false,
    enterprise: true,
    enterprisepro: true,
    desc: t('price.known_vul_tag_des')
  },
  {
    name: t('price.key_vul_tag'),
    free: false,
    professional: false,
    enterprise: true,
    enterprisepro: true,
    desc: t('price.key_vul_tag_des')
  },
  {
    name: t('price.apt_tag'),
    free: false,
    professional: false,
    enterprise: true,
    enterprisepro: true,
    desc: t('price.apt_tag_des')
  },
  // {
  //   name: t('price.view_analysis'),
  //   free: false,
  //   professional: false,
  //   enterprise: true,
  //   enterprisepro: true,
  //   desc: t('price.view_analysis_des')
  // },
  // {
  //   name: t('price.ai_assistant'),
  //   free: false,
  //   professional: false,
  //   enterprise: true,
  //   enterprisepro: true,
  //   desc: t('price.ai_assistant_des')
  // },
  // {
  //   name: t('price.qihoo'),
  //   free: '',
  //   professional: '',
  //   enterprise: '',
  //   enterprisepro: '',
  //   title: true
  // },
  // {
  //   name: t('price.advanced_search'),
  //   free: false,
  //   professional: true,
  //   enterprise: true,
  //   enterprisepro: true,
  //   desc: t('price.advanced_search_des')
  // },
  {
    name: t('price.auota_restriction'),
    free: '',
    professional: '',
    enterprise: '',
    enterprisepro: '',
    title: true
  },
  {
    name: t('price.delivery_frequency'),
    free: t('price.times', { num: 5 }),
    professional: t('price.times', { num: 15 }),
    enterprise: t('price.times', { num: 30 }),
    enterprisepro: t('price.customized'),
    desc: t('price.delivery_frequency_des')
  },
  {
    name: t('price.search_frequency'),
    free: t('price.times', { num: 10 }),
    professional: t('price.times', { num: 30 }),
    enterprise: t('price.times', { num: 60 }),
    enterprisepro: t('price.customized'),
    desc: t('price.search_frequency_des')
  },
  // {
  //   name: t('price.ai_frequency'),
  //   free: false,
  //   professional: false,
  //   enterprise: t('price.times', { num: 10 }),
  //   enterprisepro: t('price.customized'),
  //   desc: t('price.ai_frequency_des')
  // },
  {
    name: t('price.single_task'),
    free: t('price.numbers', { num: 1 }),
    professional: t('price.numbers', { num: 20 }),
    enterprise: t('price.numbers', { num: 40 }),
    enterprisepro: t('price.customized'),
    desc: t('price.single_task_des')
  },
  {
    name: t('price.task_number'),
    free: t('price.numbers', { num: 20 }),
    professional: t('price.numbers', { num: 300 }),
    enterprise: t('price.numbers', { num: 600 }),
    enterprisepro: t('price.customized'),
    desc: t('price.task_number_des')
  },
  // {
  //   name: '提交任务配额（每月）',
  //   free: '3000个',
  //   professional: '15000个',
  //   enterprise: '30000个',
  //   enterprisepro: '不限',
  //   desc: '每月允许提交的任务上限。'
  // },
  {
    name: t('price.search_number'),
    free: t('price.times', { num: 100 }),
    professional: t('price.times', { num: 300 }),
    enterprise: t('price.times', { num: 600 }),
    enterprisepro: t('price.customized'),
    desc: t('price.search_number_des')
  },
  {
    name: t('price.view_task'),
    free: t('price.times', { num: 100 }),
    professional: t('price.times', { num: 300 }),
    enterprise: t('price.times', { num: 600 }),
    enterprisepro: t('price.customized'),
    desc: t('price.view_task_des')
  },
  // {
  //   name: t('price.advanced_search_number'),
  //   free: false,
  //   professional: t('price.times', { num: 20 }),
  //   enterprise: t('price.times', { num: 100 }),
  //   enterprisepro: t('price.customized'),
  //   desc: t('price.advanced_search_number_desc')
  // },
  {
    name: t('price.download_report'),
    free: false,
    professional: t('price.numbers', { num: 60 }),
    enterprise: t('price.numbers', { num: 300 }),
    enterprisepro: t('price.customized'),
    desc: t('price.download_report_des')
  },
  {
    name: t('price.download_pdf_num'),
    free: false,
    professional: t('price.numbers', { num: 20 }),
    enterprise: t('price.numbers', { num: 100 }),
    enterprisepro: t('price.customized'),
    desc: t('price.download_pdf_num_des')
  },
  {
    name: t('price.download_pcap_num'),
    free: false,
    professional: t('price.numbers', { num: 20 }),
    enterprise: t('price.numbers', { num: 100 }),
    enterprisepro: t('price.customized'),
    desc: t('price.download_pcap_num_des')
  },
  {
    name: t('price.download_release_num'),
    free: false,
    professional: t('price.numbers', { num: 20 }),
    enterprise: t('price.numbers', { num: 100 }),
    enterprisepro: t('price.customized'),
    desc: t('price.download_release_num_des')
  },
  {
    name: t('price.download_disk_num'),
    free: false,
    professional: t('price.numbers', { num: 20 }),
    enterprise: t('price.numbers', { num: 100 }),
    enterprisepro: t('price.customized'),
    desc: t('price.download_disk_num_des')
  },
  // {
  //   name: t('price.ai_question_num'),
  //   free: false,
  //   professional: false,
  //   enterprise: t('price.times', { num: 100 }),
  //   enterprisepro: t('price.customized'),
  //   desc: t('price.ai_question_num_des')
  // },
  {
    name: t('price.teamwork'),
    free: '',
    professional: '',
    enterprise: '',
    enterprisepro: '',
    title: true
  },
  {
    name: t('price.create_team'),
    free: false,
    professional: false,
    enterprise: t('price.numbers', { num: 1 }),
    enterprisepro: t('price.customized'),
    desc: t('price.create_team_des')
  },
  {
    name: t('price.add_team_num'),
    free: false,
    professional: false,
    enterprise: t('price.persons', { num: 10 }),
    enterprisepro: t('price.customized'),
    desc: t('price.add_team_num_des')
  },
  // {
  //   name: '共享订阅权限',
  //   free: false,
  //   professional: '2人',
  //   enterprise: '5人',
  //   enterprisepro: false,
  //   desc: '共允许共享订阅权限人数。'
  // },
  {
    name: t('price.team_nums'),
    free: t('price.numbers', { num: 1 }),
    professional: t('price.numbers', { num: 1 }),
    enterprise: t('price.numbers', { num: 2 }),
    enterprisepro: t('price.customized'),
    desc: t('price.team_nums_des')
  }
  // {
  //   name: '高级威胁狩猎',
  //   free: '',
  //   professional: '',
  //   enterprise: '',
  //   enterprisepro: '',
  //   title: true
  // },
  // {
  //   name: '创建静态狩猎规则',
  //   free: false,
  //   professional: false,
  //   enterprise: '10个',
  //   enterprisepro: '定制',
  //   desc: '允许创建静态狩猎规则个数。'
  // },
  // {
  //   name: '创建威胁狩猎规则',
  //   free: false,
  //   professional: false,
  //   enterprise: '10个',
  //   enterprisepro: '定制',
  //   desc: '允许创建威胁狩猎跪着个数。'
  // }
]
